import { useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { isNumeric } from "~/lib/utils";

// undefined: loading
// null: no organization
// number: organization ID
export function useOrganizationId() {
  const { pathname } = useLocation();
  const [organizationId, setOrganizationId] = useState<
    number | null | undefined
  >(undefined);
  const [storedOrganizationId, setStoredOrganizationId] = useState<
    number | null | undefined
  >(undefined);

  useEffect(() => {
    const pathArray = pathname.split("/");
    let id = null;

    if (
      pathArray.length > 2 &&
      pathArray[1] === "community" &&
      isNumeric(pathArray[2])
    ) {
      id = Number(pathArray[2]);
    }

    setOrganizationId(id);

    // Store the organization ID in localStorage
    if (typeof window !== "undefined" && window.localStorage) {
      if (id) {
        window.localStorage.setItem("latestOrganizationId", String(id));
      }

      const data = window.localStorage.getItem("latestOrganizationId");
      setStoredOrganizationId(data && isNumeric(data) ? Number(data) : null);
    }
  }, [pathname]);

  return {
    organizationId,
    latestOrganizationId: organizationId || storedOrganizationId,
  };
}

export function clearLatestOrganizationId() {
  if (typeof window !== "undefined" && window.localStorage) {
    window.localStorage.removeItem("latestOrganizationId");
  }
}
